import * as RequestHelper from "framework/modules/requestHelper";

import { ILocale, MessageBlob } from "application/models/management/translation";
import { LocaleStore } from "framework/stores/localeStore";
import { Change, LoadingLocaleMessages, LocaleMessagesFetched } from "./localeActions";

export async function fetchLocaleMessages(locale: ILocale, part?: string) {
    const uri = "/assets/lang/" + LocaleStore.getLanguageCode(locale.id) + "Messages" + (part !== undefined ? "." + part : "") + ".json?v=1";
    new LoadingLocaleMessages(locale, part);
    const messages = await RequestHelper.getJSON<MessageBlob>(uri);
    if (!messages) throw new Error("Could not load messages for " + locale.id);
    new LocaleMessagesFetched(locale, messages, part);
    return messages;
}

export async function changeLocale(locale: ILocale, part?: string) {
    let messages = undefined;

    if (!LocaleStore.hasMessagesForLocale(locale)) {
        messages = await fetchLocaleMessages(locale, part);
    }

    new Change(locale, messages, part);
}
